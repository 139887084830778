import { onpreoClient } from "@onpreo/upsy-daisy/client";
import { FileDescriptor, imageHandler } from "@onpreo/toolbox/src/client";

const createUser = (data: any) => {
    const url = "/api/user";
    return onpreoClient.post(url, { ...data });
};

const fetchById = async (userId: string) => {
    const url = `/api/user/${userId}`;
    return await onpreoClient.get(url);
};

const fetchByEmail = async (userEmail: string) => {
    const url = `/api/user/${userEmail}`;
    return await onpreoClient.get(url);
};

const updateUser = async (userId, user) => {
    const url = `/api/user/${userId}`;
    return await onpreoClient.put(url, { user });
};

const updateUserTracking = async (userId, tracking) => {
    const url = `/api/user/${userId}/tracking`;
    return await onpreoClient.patch(url, { tracking });
};

const updateUserPassword = async passwordDetails => {
    const url = "/api/user/password-reset/change-password";
    return await onpreoClient.put(url, { passwordDetails });
};

const resetUserPassword = async passwordDetails => {
    const url = "/api/user/password-reset/change-password";
    return await onpreoClient.post(url, { passwordDetails });
};

const setUserPassword = async (userId, passwordDetails) => {
    const url = `/api/user/${userId}/password-reset`;
    return await onpreoClient.post(url, { userId, passwordDetails });
};

const setUserStatus = async (userId, data) => {
    const url = `/api/user/${userId}/set-status`;
    return await onpreoClient.patch(url, { ...data });
};

const setUserMailSignature = async data => {
    const url = `/api/user/session/mail-signature`;
    return await onpreoClient.post(url, data);
};

const uploadUserImage = async (userId, data) => {
    const url = `/api/user/${userId}/image`;
    const descriptor: FileDescriptor = { originalFilename: data.fileName, mimetype: data.png.type };
    const res = await onpreoClient.post(url, descriptor);
    await imageHandler.uploadPreSignedFile(res.data.presigned, data.png);
    return res;
};

const deleteUserImage = async userId => {
    const url = `/api/user/${userId}/image`;
    return await onpreoClient.delete(url);
};

const deleteUser = async (userId: string, data: any) => {
    const url = `/api/user/${userId}?pw=${data.password}&cancelReason=${data.cancelReason}`;
    return await onpreoClient.delete(url);
};

const setTeamUserPasswordAndCredential = async (userId, passwordDetails, updatedDataUser) => {
    const url = `/api/user/${userId}/team-user-credential`;
    return await onpreoClient.post(url, { userId, passwordDetails, updatedDataUser });
};

const setUserRole = async (userId, role) => {
    const url = `/api/user/${userId}/set-role`;
    return await onpreoClient.patch(url, { role });
};

const sendOnboardingCompleteEmail = async userId => {
    const url = `/api/user/${userId}/send-onboarding-complete-email`;
    return await onpreoClient.post(url);
};

const sendAgentDealConfirmationEmail = async (userId: string, data: object) => {
    const url = `/api/user/${userId}/send-deal-confirmation-email`;
    return await onpreoClient.post(url, { data });
};

const createBookedContent = async userId => {
    const url = `/api/user/${userId}/create-content`;
    return await onpreoClient.get(url);
};

const createLeadGenerators = async userId => {
    const url = `/api/user/${userId}/create-content`;
    return await onpreoClient.post(url);
};

const signupZap = async (data: any) => {
    const url = `/api/integrations/zapier/trigger`;
    return await onpreoClient.post(url, { ...data });
};

const verifyReCaptcha = async (data: { token: string }) => {
    const url = `/api/integrations/google/recaptcha`;
    return await onpreoClient.post(url, { ...data });
};

const validatePhone = async (data: any) => {
    const url = `/api/integrations/phone/validate`;
    return await onpreoClient.post(url, { ...data });
};

const updateUserNotifications = async (userId, notifications) => {
    const url = `/api/user/${userId}/notifications`;
    return await onpreoClient.patch(url, { notifications });
};

const updateRecipients = async (userId, recipients) => {
    const url = `/api/user/${userId}/mails-recipient`;
    return await onpreoClient.patch(url, { recipients });
};

const sendActivityNotificationEmail = async (userId: string, data: object) => {
    const url = `/api/user/${userId}/notifications`;
    return await onpreoClient.post(url, data);
};

const sendContractConfirmationMail = async (userId: string, price: string) => {
    const url = `/api/user/${userId}/contract`;
    return await onpreoClient.post(url, { price });
};

const uploadSignatureImage = async (userId, data) => {
    const url = `/api/user/${userId}/signature-image`;
    const descriptor: FileDescriptor = { originalFilename: data.fileName, mimetype: data.png.type };
    const res = await onpreoClient.post(url, descriptor);
    await imageHandler.uploadPreSignedFile(res.data.presigned, data.png);
    return res;
};

const deleteSignatureImage = async userId => {
    const url = `/api/user/${userId}/signature-image`;
    return await onpreoClient.delete(url);
};

const updateIMVSearchMap = async (searchMap, pipeline) => {
    const url = `/api/user/imv`;
    return await onpreoClient.put(url, { searchMap, pipeline });
};

const registerIMVSearchProfile = async searchProfile => {
    const url = `/api/user/imv-webhook`;
    return await onpreoClient.post(url, { searchProfile });
};

const deleteIMVSearchProfile = async id => {
    const url = `/api/user/imv-webhook`;
    return await onpreoClient.delete(url, { data: { id } });
};

const updateIMVSearchProfile = async (id, searchProfile) => {
    const url = `/api/user/imv-webhook`;
    return await onpreoClient.put(url, { id, searchProfile });
};

const userService = {
    createUser,
    fetchById,
    fetchByEmail,
    updateUser,
    updateUserTracking,
    updateUserPassword,
    resetUserPassword,
    uploadUserImage,
    deleteUserImage,
    deleteUser,
    setUserPassword,
    setUserStatus,
    setTeamUserPasswordAndCredential,
    setUserRole,
    sendOnboardingCompleteEmail,
    sendAgentDealConfirmationEmail,
    createBookedContent,
    createLeadGenerators,
    setUserMailSignature,
    signupZap,
    verifyReCaptcha,
    validatePhone,
    updateUserNotifications,
    updateRecipients,
    sendActivityNotificationEmail,
    sendContractConfirmationMail,
    uploadSignatureImage,
    deleteSignatureImage,
    updateIMVSearchMap,
    registerIMVSearchProfile,
    deleteIMVSearchProfile,
    updateIMVSearchProfile
};

export default userService;
