import { createApi } from "@reduxjs/toolkit/query/react";
import { onpreoClientBaseQuery } from "../utils";
import { onpreoClient } from "@onpreo/upsy-daisy/client";
const baseUrl = `/api/sales-statistics`;

export const salesStatisticsApi = createApi({
    reducerPath: "salesStatistics",
    baseQuery: onpreoClientBaseQuery({ baseUrl }),
    tagTypes: [""],
    endpoints: builder => ({
        getStatisticsByPipelineSteps: builder.query<any, void>({
            query: () => ({ url: "", method: "GET" })
        }),
        getStatisticsForFunnel: builder.query({
            queryFn: async ({ pipeline }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}`, { pipeline });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            }
        }),
        getStatisticsByStep: builder.query({
            queryFn: async ({ searchQuery }) => {
                try {
                    const result = await onpreoClient.post(`${baseUrl}/by-step`, { searchQuery });
                    return { data: result.data };
                } catch (error) {
                    return { error };
                }
            }
        })
    })
});

export const { useGetStatisticsByPipelineStepsQuery, useGetStatisticsForFunnelQuery, useGetStatisticsByStepQuery } = salesStatisticsApi;

export default salesStatisticsApi;
