export const NODE_ENV = process.env.NODE_ENV;
export const MONGO_URL = process.env.MONGO_URL;

export const MJ_APIKEY_PUBLIC = process.env.MJ_APIKEY_PUBLIC;
export const MJ_APIKEY_PRIVATE = process.env.MJ_APIKEY_PRIVATE;

export const JWT_SECRET = process.env.JWT_SECRET;
export const NEXTAUTH_SECRET = process.env.NEXTAUTH_SECRET;

export const RUNNER_SECRET = process.env.RUNNER_SECRET;

export const AWS_ACCESS_KEY = process.env.ONPREO_AWS_ACCESS_KEY;
export const AWS_BUCKET = process.env.AWS_BUCKET;
export const AWS_BUCKET_PRIVATE = process.env.AWS_BUCKET_PRIVATE;
export const AWS_BUCKET_CLOUDMAILIN = process.env.AWS_BUCKET_CLOUDMAILIN;

export const AWS_SECRET_ACCESS_KEY = process.env.ONPREO_AWS_SECRET_ACCESS_KEY;
export const PROTOCOL = NODE_ENV === "development" ? "http://" : "https://";
export const AWS_REGION = "eu-central-1";
export const AWS_IMAGE_URL = `${PROTOCOL}${AWS_BUCKET}.s3.${AWS_REGION}.amazonaws.com/`;

export const STRIPE_PUB_KEY = process.env.NEXT_PUBLIC_STRIPE_KEY;
export const STRIPE_SECRET_KEY = process.env.STRIPE_SECRET_KEY;

export const SMASHLEADS_WEBHOOK_SECRET = process.env.SMASHLEADS_WEBHOOK_SECRET;
export const USERPILOT_KEY = process.env.NEXT_PUBLIC_USERPILOT_KEY;
export const USERPILOT_ACTIVE_TRACKING = process.env.NEXT_PUBLIC_USERPILOT_ACTIVE_TRACKING;

export const HUBSPOT_API_KEY = process.env.HUBSPOT_API_KEY;

export const GUEST_APP_URL = process.env.NEXT_PUBLIC_GUEST_APP_URL;
export const BROKER_APP_URL = process.env.NEXT_PUBLIC_BROKER_APP_URL;
export const PRICE_ASSISTANT_URL = process.env.NEXT_PUBLIC_PRICE_ASSISTANT_URL;
export const EXPOSE_ASSISTANT_URL = process.env.NEXT_PUBLIC_EXPOSE_ASSISTANT_URL;
export const SMASHLEADS_URL = process.env.NEXT_PUBLIC_SMASHLEADS_URL;
export const RUNNER_URL = process.env.NEXT_PUBLIC_RUNNER_URL;
export const RE_LP_URL = process.env.NEXT_PUBLIC_RE_LP_URL;

export const GTM_CONTAINER_ID = process.env.NEXT_PUBLIC_GTM_CONTAINER_ID;

export const BUYER_APP_URL = process.env.NEXT_PUBLIC_BUYER_APP_URL;
export const AUTOMATION_ENGINE_URL = process.env.AUTOMATION_ENGINE_URL;
export const AUTOMATION_ENGINE_API_KEY = process.env.AUTOMATION_ENGINE_API_KEY ?? "test-api-key";

export const IMMOWELT_FTP = "ftp.immowelt.net";
export const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
export const EXCHANGE_RATE_URL = process.env.EXCHANGE_RATE_URL;
export const EXCHANGE_RATE_API_KEY = process.env.EXCHANGE_RATE_API_KEY;
export const SMASH_MONGO_URL = process.env.SMASH_MONGO_URL;
export const SMASH_STAGING_MONGO_URL = process.env.SMASH_STAGING_MONGO_URL;
export const EMAIL = process.env.EMAIL;
export const FORM_EXTENSION = NODE_ENV === "development" ? "xyz" : "io";

export const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;
export const SPRENGNETTER_USER = process.env.SPRENGNETTER_USER;
export const SPRENGNETTER_PW = process.env.SPRENGNETTER_PW;

export const GOOGLE_CLIENT_ID = process.env.GOOGLE_CLIENT_ID;
export const GOOGLE_CLIENT_SECRET = process.env.GOOGLE_CLIENT_SECRET;

export const OUTLOOK_CLIENT_ID = process.env.OUTLOOK_CLIENT_ID;
export const OUTLOOK_CLIENT_SECRET = process.env.OUTLOOK_CLIENT_SECRET;

export const QLOAKED_API_KEY = process.env.QLOAKED_API_KEY;
export const QLOAKED_DOMAIN = process.env.NEXT_PUBLIC_QLOAKED_DOMAIN;

export const OPENAI_ORG_ID = process.env.OPENAI_ORG_ID;
export const OPENAI_API_KEY = process.env.OPENAI_API_KEY;

export const TWILIO_ACCOUNT_SID = process.env.TWILIO_ACCOUNT_SID;
export const TWILIO_AUTH_TOKEN = process.env.TWILIO_AUTH_TOKEN;

// Email Engine Integration
export const EE_ACCESS_KEY = process.env.EE_ACCESS_KEY;
export const EE_URL = process.env.NEXT_PUBLIC_EE_URL;
export const EE_TOKEN_SECRET = process.env.EE_TOKEN_SECRET;

// Partnerstack Integration
export const PARTNERSTACK_TOKEN = process.env.PARTNERSTACK_TOKEN;
export const PARTNERSTACK_PUBLIC = process.env.NEXT_PUBLIC_PARTNERSTACK_PUBLIC;
export const PARTNERSTACK_SECRET = process.env.PARTNERSTACK_SECRET;

// NOTE: has to be next public, since it also toggles tabs in the frontend
export const FEATURE_EMAIL = process.env.NEXT_PUBLIC_FEATURE_EMAIL !== undefined;
export const FEATURE_CSV_IMPORT = process.env.NEXT_PUBLIC_FEATURE_CSV_IMPORT !== undefined;
export const FEATURE_RELP = process.env.NEXT_PUBLIC_FEATURE_RELP !== undefined;
export const FEATURE_CALENDAR = process.env.NEXT_PUBLIC_FEATURE_CALENDAR !== undefined;
export const FEATURE_GPT_EXPOSE = process.env.NEXT_PUBLIC_FEATURE_GPT_EXPOSE !== undefined;
export const FEATURE_SEPA = process.env.NEXT_PUBLIC_FEATURE_SEPA !== undefined;
export const FEATURE_IMV = process.env.NEXT_PUBLIC_FEATURE_IMV !== undefined;

export const MAPBOX_ACCESS_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN;

export const PRICE_HUBBLE_USERNAME = process.env.PRICE_HUBBLE_USERNAME;
export const PRICE_HUBBLE_PASSWORD = process.env.PRICE_HUBBLE_PASSWORD;
export const PRICE_HUBBLE_DOSSIER_USERNAME = process.env.PRICE_HUBBLE_DOSSIER_USERNAME;
export const PRICE_HUBBLE_DOSSIER_PASSWORD = process.env.PRICE_HUBBLE_DOSSIER_PASSWORD;
export const PRICE_HUBBLE_CERBERUS_USERNAME = process.env.PRICE_HUBBLE_CERBERUS_USERNAME;
export const PRICE_HUBBLE_CERBERUS_PASSWORD = process.env.PRICE_HUBBLE_CERBERUS_PASSWORD;

export const FEATURE_APPOINTMENT = process.env.NEXT_PUBLIC_FEATURE_APPOINTMENT !== undefined;

export const RECAPTCHA_SITEKEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY;
export const RECAPTCHA_SECRET = process.env.RECAPTCHA_SECRET;
export const FEATURE_PROPSTACK = process.env.NEXT_PUBLIC_FEATURE_PROPSTACK !== undefined;
export const FEATURE_ONOFFICE = process.env.NEXT_PUBLIC_FEATURE_ONOFFICE !== undefined;
export const FEATURE_MMCENTER = process.env.NEXT_PUBLIC_FEATURE_MMCENTER !== undefined;

export const SLACK_WEBHOOK_ERRORS = process.env.SLACK_WEBHOOK;
export const BLACK_WEEK_COUPON = process.env.NEXT_PUBLIC_BLACK_WEEK_COUPON;
export const FEATURE_PRICE_HUBBLE = process.env.NEXT_PUBLIC_FEATURE_PRICE_HUBBLE !== undefined;
export const FEATURE_PRICE_HUBBLE_DOSSIER = process.env.NEXT_PUBLIC_FEATURE_PRICE_HUBBLE_DOSSIER !== undefined;
export const FEATURE_RE_SETTINGS = process.env.NEXT_PUBLIC_FEATURE_RE_SETTINGS !== undefined;
export const FEATURE_DANGER_ZONE = process.env.NEXT_PUBLIC_FEATURE_DANGER_ZONE !== undefined;
export const IMV_USERNAME = process.env.NEXT_PUBLIC_IMV_USERNAME;
export const IMV_PASSWORD = process.env.NEXT_PUBLIC_IMV_PASSWORD;
export const FEATURE_MAIL_DESIGNER = process.env.NEXT_PUBLIC_FEATURE_MAIL_DESIGNER !== undefined;
