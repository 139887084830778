import { UserDocument, UserTracking } from "@onpreo/database";
import { createNotification, enqueueSnackbar } from "@onpreo/slices";
import { createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../services/user.service";
import { setUser } from "../slices/user.slice";
import { setWorkspace } from "../slices/workspace.slice";

export const $fetchUserById = createAsyncThunk("user/$fetchById", async (userId: string, thunkAPI) => {
    const response = await userService.fetchById(userId);
    thunkAPI.dispatch(setUser(response.data.user));
    return response.data;
});

export const $fetchUserByEmail = createAsyncThunk("user/$fetchByEmail", async (userId: string, thunkAPI) => {
    const response = await userService.fetchById(userId);
    thunkAPI.dispatch(setUser(response.data.user));
    return response.data;
});

interface UpdateUserThunk {
    userId: string;
    user: any;
}

export const $updateUser = createAsyncThunk("user/$updateUser", async ({ userId, user }: UpdateUserThunk, thunkAPI) => {
    const response = await userService.updateUser(userId, user);
    if (user?.calendarType) {
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Verbindung wird erstellt. Sie werden automatisch weitergeleitet.", "success")));
        return response.status;
    } else {
        thunkAPI.dispatch(setUser(response.data.user));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Profildaten wurden erfolgreich aktualisiert", "success")));
        return response.data;
    }
});

interface UpdateUserTrackingThunk {
    userId: string;
    tracking: UserTracking;
}
export const $updateUserTracking = createAsyncThunk("user/$updateUserTracking", async ({ userId, tracking }: UpdateUserTrackingThunk, thunkAPI) => {
    const response = await userService.updateUserTracking(userId, tracking);
    thunkAPI.dispatch(setUser(response.data.user));
    // thunkAPI.dispatch(enqueueSnackbar(createNotification("Profildaten wurden erfolgreich aktualisiert", "success")));
    return response.data;
});

interface UpdateUserPasswordThunk {
    passwordDetails: any;
}

export const $updateUserPassword = createAsyncThunk("user/$updateUserPassword", async ({ passwordDetails }: UpdateUserPasswordThunk, thunkAPI) => {
    const response = await userService.updateUserPassword(passwordDetails);
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Passwort wurde erfolgreich geändert", "success")));
    return response.data;
});

interface UploadUserImageThunk {
    userId: string;
    data: { png: Blob; fileName: string };
}

export const $uploadImage = createAsyncThunk("user/$uploadImage", async ({ userId, data }: UploadUserImageThunk, thunkAPI) => {
    const response = await userService.uploadUserImage(userId, data);
    thunkAPI.dispatch(setUser(response.data.user));
    return response.data;
});

interface UpdateUserImageThunk {
    userId: string;
    data: { png: Blob; fileName: string };
}

export const $updateImage = createAsyncThunk("user/$updateImage", async ({ userId, data }: UpdateUserImageThunk, thunkAPI) => {
    await userService.deleteUserImage(userId);

    const response = await userService.uploadUserImage(userId, data);
    thunkAPI.dispatch(setUser(response.data.user));
    return response.data;
});

interface DeleteUserImageThunk {
    userId: string;
}

export const $deleteImage = createAsyncThunk("user/$deleteImage", async ({ userId }: DeleteUserImageThunk, thunkAPI) => {
    const response = await userService.deleteUserImage(userId);
    thunkAPI.dispatch(setUser(response.data.user));
    return response.data;
});

interface ResetUserPasswordThunk {
    passwordDetails: any;
}

export const $resetUserPassword = createAsyncThunk("user/$resetUserPassword", async ({ passwordDetails }: ResetUserPasswordThunk, thunkAPI) => {
    const response = await userService.resetUserPassword(passwordDetails);
    return response;
});

interface DeleteUserThunk {
    userId: string;
    data: any;
}

export const $deleteUser = createAsyncThunk("user/$deleteUser", async ({ userId, data }: DeleteUserThunk, thunkAPI) => {
    await userService.deleteUser(userId, data);
});

interface SetUserStatusThunk {
    userId: string;
    data: { status: string; statusValue: boolean };
}
export const $setUserStatus = createAsyncThunk("user/$setUserStatus", async ({ userId, data }: SetUserStatusThunk, thunkAPI) => {
    const response = await userService.setUserStatus(userId, data);
    thunkAPI.dispatch(setUser(response.data.user));
    return response.data;
});

interface SetUserRoleThunk {
    userId: string;
    role: "admin" | "user" | "salesPartner";
}
export const $setUserRole = createAsyncThunk("user/$setUserRole", async ({ userId, role }: SetUserRoleThunk, thunkAPI) => {
    const response = await userService.setUserRole(userId, role);
    thunkAPI.dispatch(setWorkspace(response.data.workspace));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
    return response.data;
});

export const $sendOnboardingCompleteEmail = createAsyncThunk("user/$sendOnboardingCompleteEmail", async (userId: string, thunkAPI) => {
    const response = await userService.sendOnboardingCompleteEmail(userId);
    return response.data;
});

interface DealConfirmationThunk {
    userId: string;
    data: object;
}

export const $sendAgentDealConfirmationEmail = createAsyncThunk(
    "user/$sendAgentDealConfirmationEmail",
    async ({ userId, data }: DealConfirmationThunk, thunkAPI) => {
        const response = await userService.sendAgentDealConfirmationEmail(userId, data);
        return response.data;
    }
);

export const $createBookedContent = createAsyncThunk("$createBookedContent", async ({ userId }: { userId: string }, thunkAPI) => {
    const response = await userService.createBookedContent(userId);
    return response.data;
});

export const $createLeadGenerators = createAsyncThunk("$createLeadGenerators", async ({ userId }: { userId: string }, thunkAPI) => {
    const response = await userService.createLeadGenerators(userId);
    return response.data;
});

export const $setUserMailSignature = createAsyncThunk("user/$setUserMailSignature", async (data: UserDocument["mailConfig"]["signature"], thunkAPI) => {
    const response = await userService.setUserMailSignature(data);
    thunkAPI.dispatch(setUser(response.data.user));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
    return response.data;
});

interface UpdateUserNotificationsThunk {
    userId: string;
    notifications: any;
}
export const $updateUserNotifications = createAsyncThunk(
    "user/$updateUserNotifications",
    async ({ userId, notifications }: UpdateUserNotificationsThunk, thunkAPI) => {
        const response = await userService.updateUserNotifications(userId, notifications);
        thunkAPI.dispatch(setUser(response.data.user));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $updateRecipients = createAsyncThunk(
    "user/$updateRecipients",
    async ({ userId, recipients }: { userId: string; recipients: any }, thunkAPI) => {
        const response = await userService.updateRecipients(userId, recipients);
        thunkAPI.dispatch(setUser(response.data.user));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $sendActivityNotificationEmail = createAsyncThunk(
    "user/$sendActivityNotificationEmail",
    async ({ userId, data }: { userId: string; data: any }, thunkAPI) => {
        const response = await userService.sendActivityNotificationEmail(userId, data);
        return response.data;
    }
);

export const $sendContractConfirmationMail = createAsyncThunk(
    "user/$sendContractConfirmationMail",
    async ({ userId, price }: { userId: string; price: string }, thunkAPI) => {
        const response = await userService.sendContractConfirmationMail(userId, price);
        return response.data;
    }
);

export const $uploadSignatureImage = createAsyncThunk("user/$uploadSignatureImage", async ({ userId, data }: UploadUserImageThunk, thunkAPI) => {
    const response = await userService.uploadSignatureImage(userId, data);
    thunkAPI.dispatch(setUser(response.data.user));
    return response.data;
});

export const $updateSignatureImage = createAsyncThunk("user/$updateSignatureImage", async ({ userId, data }: UpdateUserImageThunk, thunkAPI) => {
    await userService.deleteSignatureImage(userId);

    const response = await userService.uploadSignatureImage(userId, data);
    thunkAPI.dispatch(setUser(response.data.user));
    return response.data;
});

export const $deleteSignatureImage = createAsyncThunk("user/$deleteSignatureImage", async ({ userId }: DeleteUserImageThunk, thunkAPI) => {
    const response = await userService.deleteSignatureImage(userId);
    thunkAPI.dispatch(setUser(response.data.user));
    return response.data;
});

export const $updateIMVSearchMap = createAsyncThunk(
    "user/$updateIMVSearchMap",
    async ({ searchMap, pipeline }: { searchMap: any; pipeline: any }, thunkAPI) => {
        const response = await userService.updateIMVSearchMap(searchMap, pipeline);
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $registerIMVSearchProfile = createAsyncThunk("user/$registerIMVSearchProfile", async ({ searchProfile }: { searchProfile: any }, thunkAPI) => {
    const response = await userService.registerIMVSearchProfile(searchProfile);
    thunkAPI.dispatch(setUser(response.data.user));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
    return response.data;
});

export const $deleteIMVSearchProfile = createAsyncThunk("user/$deleteIMVSearchProfile", async ({ id }: { id: number }, thunkAPI) => {
    const response = await userService.deleteIMVSearchProfile(id);
    thunkAPI.dispatch(setUser(response.data.user));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
    return response.data;
});

export const $updateIMVSearchProfile = createAsyncThunk(
    "user/$updateIMVSearchProfile",
    async ({ id, searchProfile }: { id: number; searchProfile: any }, thunkAPI) => {
        const response = await userService.updateIMVSearchProfile(id, searchProfile);
        thunkAPI.dispatch(setUser(response.data.user));
        thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);
